import logo from './logo.svg';
import './App.scss';
import Heading from "./componets/Heading/Heading"
import BackGround from './componets/BackGround/BackGround';
import HomePage from "./Pages/HomePage/HomePage"
import { Routes, Route, BrowserRouter } from "react-router-dom";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />


        </Routes>

      </BrowserRouter>


    </div>
  );
}

export default App;
