import React from 'react';
import "./Title.scss"
import waves from "/Users/jorgecasaudoumecq/Desktop/portfolio/jorge-portfolio/src/assets/images/bluewaves.d6d84b33.svg"
import { Link } from "react-scroll"
function Title() {





    return (
        <>

            <div className='header'>
                <nav className='header__nav'>
                    <ul className='header__nav-list'>
                        <li>
                            <a className='link-remove' >
                                <Link to="this" smooth={true}>

                                    <h3 className='item-hover'>Projects</h3>

                                </Link>
                            </a>
                        </li>
                        <li>
                            <a className='link-remove' href='https://www.linkedin.com/in/jorge-casaudoumecq/'>
                                <h3 className='item-hover'>Linkedin</h3>
                            </a >
                        </li>
                        <li>
                            <a className='link-remove' href='https://github.com/JorgeJose26?tab=repositories'>
                                <h3 className='item-hover'>GitHub</h3>
                            </a>
                        </li>
                        <li>
                            <a className='link-remove' href='https://www.linkedin.com/in/jorge-casaudoumecq/'>
                                <h3 className='item-hover'>Contact</h3>
                            </a>
                        </li>
                    </ul>

                </nav>
                <img className='waves' src={waves}></img>
                <h1 className='myName'> Hi, I'm Jorge Casaudoumecq</h1>

            </div>

        </>
    );
}

export default Title;