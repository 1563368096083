import React from 'react';
import "./Card.scss"
import BookImg from "/Users/jorgecasaudoumecq/Desktop/portfolio/jorge-portfolio/src/assets/images/plants.4fff0ea1.svg"

function Card() {
    return (
        <div className='card'>
            <h3 className='card__title'>Software Engineer</h3>
            <p className='card__desc'>Studied Computer Science at Miami Dade College whereafter I joined BrainStation bootcamp
                for my fullstack web development certficate.</p>
            <p className='card__desc'>Im a full-stack web developer with extensive experience in both front-end and back-end development. My passion for crafting elegant and efficient web solutions is matched only by my love for video games and my enthusiasm for collaborating with others on exciting projects. I bring a well-rounded skillset to the table, with a deep understanding of the latest web technologies and a proven track record of delivering high-quality results.</p>

            <img className='card__img' src={BookImg}></img>


        </div>
    );
}

export default Card;