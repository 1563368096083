import React from 'react';
import Heading from "../../componets/Heading/Heading"
import Title from "../../componets/Title/Title"
import Card from "../../componets/Card/Card"
import Skills from "../../componets/Skills/Skills"
import Projects from '../../componets/Projects/Projects';
function HomePage() {
    return (
        <div className='fit-content'>
            <Title />
            <Card />
            <Skills />
            <Projects />
        </div>
    );
}

export default HomePage;