import React from 'react';
import "./Skills.scss"

function Skills() {
    return (
        <section className='skills'>
            <h2>Skills</h2>
            <hr className='projects__header-underline'></hr>
            <div className='languages'>
                <h3>Languages</h3>
                <ul className='list'>
                    <li className='list__item list_item--languages'>JavaScript</li>
                    <li className='list__item list_item--languages'>Java</li>
                    <li className='list__item list_item--languages'>Kotlin</li>
                    <li className='list__item list_item--languages'>Objective-C</li>
                    <li className='list__item list_item--languages'>Swift</li>
                    <li className='list__item list_item--languages'>HTML</li>
                    <li className='list__item list_item--languages'>CSS</li>
                </ul>

            </div>
            <div className='databases'>
                <h3>Databases</h3>
                <ul className='list'>
                    <li className="list__item list_item--databases">MySql</li>
                    <li className="list__item list_item--databases">MongoDB</li>
                    <li className="list__item list_item--databases">FireBase RTDB</li>
                </ul>



            </div>
            <div className='tools'>
                <h3>Tools</h3>
                <ul className='list'>
                    <li className="list__item list_item--tools">React</li>
                    <li className="list__item list_item--tools">Nodejs</li>
                    <li className="list__item list_item--tools">Vue</li>
                    <li className="list__item list_item--tools">Visual Studio Code</li>
                    <li className="list__item list_item--tools">SASS</li>
                </ul>

            </div>


        </section>

    );
}

export default Skills;