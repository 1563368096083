import { React, useRef, useState, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import "./Projects.scss"
import TableTopImg from "/Users/jorgecasaudoumecq/Desktop/portfolio/jorge-portfolio/src/assets/images/chrome-capture-2022-10-21.gif"
import Hackaton from "/Users/jorgecasaudoumecq/Desktop/portfolio/jorge-portfolio/src/assets/images/Hackaton.jpg";
import BrainFlix from "/Users/jorgecasaudoumecq/Desktop/portfolio/jorge-portfolio/src/assets/images/chrome-capture-2023-0-3.gif";
import InStock from "/Users/jorgecasaudoumecq/Desktop/portfolio/jorge-portfolio/src/assets/images/chrome-capture-2023-0-4.gif";
import JirehSpa from "/Users/jorgecasaudoumecq/Desktop/portfolio/jorge-portfolio/src/assets/images/chrome-capture-2023-0-12.gif"
function Projects() {

    const { ref: myRef, inView: myElementIsVisable } = useInView();
    const { ref: twoRef, inView: gradientTwoIsVisable } = useInView();
    const { ref: threeRef, inView: gradientThreeIsViable } = useInView();
    const { ref: fourRef, inView: gradientFourIsVisable } = useInView();
    const { ref: fiveRef, inView: gradientFiveIsVisable } = useInView();



    return (
        <div id='this'>
            <section className='projects'>
                <header className='projects__header'>
                    <h2 className='projects__header-title'>Projects</h2>
                    <hr className='projects__header-underline'></hr>


                </header>

                <div ref={myRef} className={`gradients ${myElementIsVisable ? `animate` : ''}`}>
                    <div className='project'>
                        <a href='https://table-top-spotter.web.app/' className='table_top-container'>
                            <img className='project__img tabletop' src={TableTopImg}></img>

                        </a>
                        <div className='project__info'>
                            <h3 className='project__info--title'>
                                <a>TableTop Spotter</a>
                            </h3>
                            <p className='project__info--description'>
                                TableTop Spotter is an application aimed to allow users to select a perfect board game using select filters.
                                <br>
                                </br>
                                <br>
                                </br>
                                In short, the application also handles authentication, authorization, validation, errors, security and efficiently queries FireBase through ES6 standards. The user interface was designed using SASS and developed with React.
                            </p>
                            <p className='project__info--link'>
                                <a href='https://github.com/JorgeJose26?tab=repositories'>GitHub Link</a>
                            </p>
                            <ul className='project__info--list'>
                                <li className='project__info--list-item'>HTML</li>
                                <li className='project__info--list-item'>CSS</li>
                                <li className='project__info--list-item'>JavaScript</li>
                                <li className='project__info--list-item'>React</li>
                                <li className='project__info--list-item'>Firebase</li>
                                <li className='project__info--list-item'>SASS</li>
                            </ul>


                        </div>


                    </div>

                </div>

                <div ref={twoRef} className={`gradients__two ${gradientTwoIsVisable ? `animate` : ''}`}>
                    <div className='project'>
                        <a href='https://github.com/JorgeJose26/co-worker-matchup' className='Coffee Break'>
                            <img className='project__img tabletop' src={Hackaton}></img>
                        </a>
                        <div className='project__info'>
                            <h3 className='project__info--title'>
                                <a>Microsoft Hackaton </a>
                            </h3>
                            <p className='project__info--description'>
                                Coffee Break is an application created to serve co-workers socalize with each other while working remote, This
                                application was created during a 24-hour hackaton. The Hackaton was hosted by BrainStation and MicroSoft and the problem
                                statement was presented by Microsoft


                            </p>
                            <p className='project__info--link'>
                                <a href='https://github.com/JorgeJose26/co-worker-matchup'>GitHub Link</a>
                            </p>
                            <ul className='project__info--list'>
                                <li className='project__info--list-item-yellow'>HTML</li>
                                <li className='project__info--list-item-yellow'>CSS</li>
                                <li className='project__info--list-item-yellow'>JavaScript</li>
                                <li className='project__info--list-item-yellow'>React</li>
                                <li className='project__info--list-item-yellow'>SASS</li>
                            </ul>


                        </div>


                    </div>

                </div>
                <div ref={threeRef} className={`gradients__three ${gradientThreeIsViable ? `animate` : ''}`}>
                    <div className='project'>
                        <a href='https://github.com/JorgeJose26/jorge-casaudoumecq-braninflix' className='BrainFlix'>
                            <img className='project__img tabletop' src={BrainFlix}></img>
                        </a>
                        <div className='project__info'>
                            <h3 className='project__info--title'>
                                <a>BrainFlix</a>
                            </h3>
                            <p className='project__info--description'>
                                BrainFlix is a project developed during my BrainStation bootcamp. This application is a simple landing page. The project was developed
                                using a backend server as well.


                            </p>
                            <p className='project__info--link'>
                                <a href="https://github.com/JorgeJose26/jorge-casaudoumecq-braninflix">GitHub Link</a>
                            </p>
                            <ul className='project__info--list'>
                                <li className='project__info--list-item-red'>HTML</li>
                                <li className='project__info--list-item-red'>CSS</li>
                                <li className='project__info--list-item-red'>JavaScript</li>
                                <li className='project__info--list-item-red'>React</li>
                                <li className='project__info--list-item-red'>SASS</li>
                                <li className='project__info--list-item-red'>EXPRESS</li>
                            </ul>


                        </div>


                    </div>

                </div>

                <div ref={fourRef} className={`gradients__four ${gradientFourIsVisable ? `animate` : ''}`}>
                    <div className='project'>
                        <a href='https://github.com/JorgeJose26/jorge-casaudoumecq-braninflix' className='InStock'>
                            <img className='project__img tabletop' src={InStock}></img>
                        </a>
                        <div className='project__info'>
                            <h3 className='project__info--title'>
                                <a>InStock</a>
                            </h3>
                            <p className='project__info--description'>
                                InStock is a inventory mangement application created with other developers using an agile development cycle.
                                <br></br>
                                Create new warehouse's and inventory items, also delete warehouses and inventory items.


                            </p>
                            <p className='project__info--link'>
                                <a href="https://github.com/JorgeJose26/instock-best-group-api">GitHub Link</a>
                            </p>
                            <ul className='project__info--list'>
                                <li className='project__info--list-item-blue'>HTML</li>
                                <li className='project__info--list-item-blue'>CSS</li>
                                <li className='project__info--list-item-blue'>JavaScript</li>
                                <li className='project__info--list-item-blue'>React</li>
                                <li className='project__info--list-item-blue'>SASS</li>
                                <li className='project__info--list-item-blue'>EXPRESS</li>
                            </ul>


                        </div>


                    </div>

                </div>
                <div ref={fiveRef} className={`gradients__five ${gradientFiveIsVisable ? `animate` : ''}`}>
                    <div className='project'>
                        <a href='http://jireh-project.web.app/' className='JirehSpa'>
                            <img className='project__img tabletop' src={JirehSpa}></img>
                        </a>
                        <div className='project__info'>
                            <h3 className='project__info--title'>
                                <a>JirehSpa</a>
                            </h3>
                            <p className='project__info--description'>
                                JirehSpa is a simple single page application created for booking appointments at a local spa based in Miami FL
                                <br></br>
                                Using Email.js to book appointment to JirehSpa.


                            </p>
                            <p className='project__info--link'>
                                <a href="https://github.com/JorgeJose26/instock-best-group-api">GitHub Link</a>
                            </p>
                            <ul className='project__info--list'>
                                <li className='project__info--list-item-pink'>HTML</li>
                                <li className='project__info--list-item-pink'>CSS</li>
                                <li className='project__info--list-item-pink'>JavaScript</li>
                                <li className='project__info--list-item-pink'>React</li>
                                <li className='project__info--list-item-pink'>SASS</li>
                                <li className='project__info--list-item-pink'>Email.js</li>
                                <li className='project__info--list-item-pink'>MDB</li>
                            </ul>


                        </div>


                    </div>

                </div>



            </section>
        </div>
    );
}

export default Projects;